@import "assets/styles/main";


html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}


